import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "antd/dist/reset.css";

// pages

import ChangePassword from "./page/changePassword";
import ErrorPage from "./page/404.page";
import ExpiredLinkPage from "./page/expired-link.page";
import SuccessPage from "./page/success.page";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ChangePassword />} />
        <Route path="/404" element={<ErrorPage />} />
        <Route path="/expired-link" element={<ExpiredLinkPage />} />
        <Route path="/success" element={<SuccessPage />} />
      </Routes>
    </Router>
  );
};

export default App;
