import { useEffect, useState } from "react";
import "../App.css";
import {
  Card,
  Typography,
  Form,
  Button,
  Input,
  Spin,
  Flex,
  notification,
} from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../helper/axios";
const { Title, Text } = Typography;

function ChangePassword() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [clientReady, setClientReady] = useState(false);

  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { token } = queryParams
    ? { token: queryParams.get("token") }
    : { token: null };

  const { platform } = queryParams
    ? { platform: queryParams.get("platform") }
    : { platform: null };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  useEffect(() => {
    const delay = 2000;
    const verifyToken = async () => {
      setLoading(true);
      try {
        await new Promise((resolve) => setTimeout(resolve, delay));

        const response = await axiosInstance().get(
          `/api/reset-password-verifier?key=${token}`
        );

        setTokenIsValid(response.data.tokenIsValid);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("Error verifying token:", error);
      }
    };

    if (token) {
      verifyToken();
    }
  }, [token]);

  useEffect(() => {
    setClientReady(true);
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    const response = await axiosInstance().patch(
      "/api/reset-password-verifier",
      {
        token,
        newPassword: values.newPassword,
        platform: platform ? platform : null,
      }
    );

    if (response.data.passwordUpdated) {
      setLoading(false);
      navigate("/success");
    } else {
      openNotification("ERROR", "Something went wrong");
      setLoading(false);
    }
  };

  if (!token) {
    navigate("/404");
  }

  if (tokenIsValid === false && loading === false) {
    navigate("/expired-link");
  }

  if (!tokenIsValid) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: "100vh" }}>
        <Spin size="large" />
      </Flex>
    );
  }

  return (
    <>
      (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "100vh",
          padding: "0 10px",
          boxSizing: "border-box",
        }}
      >
        <Card
          style={{
            width: "100%",
            maxWidth: 500,
            marginTop: "20px",
          }}
        >
          <Title level={2} style={{ margin: 0 }}>
            Change password
          </Title>
          <Text type="secondary" style={{ fontSize: 16 }}>
            Enter your new password below
          </Text>
          <div style={{ marginTop: 20 }}>
            <Form
              form={form}
              name="reset_form"
              layout="vertical"
              onFinish={onFinish}
            >
              <Form.Item
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="New password"
                  type="password"
                />
              </Form.Item>
              <Form.Item
                name="verifyPassword"
                rules={[
                  {
                    required: true,
                    message: "Please verify your new password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("passwords do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Verify new password"
                />
              </Form.Item>
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !clientReady ||
                      !form.isFieldsTouched(true) ||
                      !!form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                    style={{
                      backgroundColor:
                        !clientReady ||
                        !form.isFieldsTouched(true) ||
                        !!form
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length
                          ? "#d9d9d9"
                          : "#00BF63",
                    }}
                  >
                    Save
                  </Button>
                )}
              </Form.Item>
            </Form>
          </div>
        </Card>
      </div>
      )
    </>
  );
}

export default ChangePassword;
