import React from "react";
import { Result } from "antd";

const SuccessPage = () => {
  return (
    <Result
      status="success"
      title="Password Update Successful!"
      subTitle=" You can now log in with your updated password"
    />
  );
};

export default SuccessPage;
